export const en = {
  "navbar": {
    "home":"HOME",
    "news":"EVENTS",
    "activity":"NEWS",
    "economy":"ECONOMY",
    "member":"MEMBERSHIP",
    "about":"ABOUT",
    "contactus":"CONTACT US",
    "department":"RELATED ORGANISATION",
    "dropdown":{
      "vision_mission":"VISION AND MISSION",
      "about_thaichinese":"HISTORY",
      "about_president_thaichinese":"PRESIDENT",
      "relation":"UPCOMMING EVENTS",
      "activity":"NEWS",
      "relation_media":"MEDIA",
      "ThaiChinese_articles":"ARTICLE",
      "ThaiChinese_belief":"THAI-CC SENTIMENT INDEX",
      "Capital_investment_thai":"INVESTMENT IN THAILAND",
      "Capital_investment_china":"INVESTMENT IN CHINA",
      "board_member":"BOARD OF DIRECTORS",
      "hornor_member":"BOARD OF HONORARY DIRECTORS",
      "member":"MEMBER DIRECTORY",
      "register":"MEMBERSHIP",
      "Entrepreneurs_Convention":"World Chinese Entrepreneurs Convention (WCEC) ",
      "Joint_Foreign":'"THE JOINT FOREIGN CHAMBERS OF COMMERCE IN THAILAND (JFCCT)"',
      "ThaiCC_Related":"Confederation of The Thai-Chinese Chamber of Commerce and Affiliated Business Associations",
    }
  },
  "register":{
    "member":"MEMBERSHIP INFORMATION",
    "register":"MEMBERSHIP APPLICATION FORM",
    "download":"DOWNLOAD FILE"
  },
  "vision_mission":{
    "vision":'VISION',
    "mission":"MISSION"
  },
  "about":{
    "chamber_of_commerce_network":'CHAMBER OF COMMERCE NETWORK',
    "History_title":"HISTORY"
  },
  "president":{
    "president":"PRESIDENT",
    "president_previous":"PREVIOUS PRESIDENT"
  },
  "boardMember":{
    "header1":"THE 27TH",
    "header2":"BOARD OF DIRECTORS",
    "vice_president":"VICE PRESIDENT",
    "secretary_general":"SECRETARY GENERAL",
    "deputy_secretary_general":"DEPUTY SECRETARY GENERAL",
    "assistant_secretary_general":"ASSISTANT SECRETARY GENERAL",
    "error_search_invalid":"กรุณาเลือกตำแหน่งและฝ่าย",
    "error_search_title":"เกิดข้อผิดพลาด",
    "and": " AND"
  },
  "home":{
    "vision_mission":"VISION & MISSION",
    "about":"HISTORY",
    "economyThaiChinese":"THAI-CHINESE ECONOMIC",
    "highlight":"HIGHLIGHT",
    "ThaiChinese_belief":"SENTIMENT INDEX",
    "relation":"THAI-CHINESE CHAMBER OF COMMERCE",
    "more":"MORE",
    "president":"PRESIDENT OF THAI-CHINESE CHAMBER OF COMMERCE",
  },
  "honor":{
    "status":"HONORARY PRESIDENT"
  },
  "socialMedia":{
    "share":"Share"
  },
  "searchBoardMember":{
    "title":"The 28TH BOARD OF DIRECTION",
    "topsearch":"Search results"
  },
  "MemberDirectory":{
    "type_company":"Type of Company",
    "placeholder" :"Search...",
    "goBack": "Back"
  }
}