export const ch = {
    "navbar": {
      "home":"首頁",
      "news":"商會新聞",
      "activity":"活動推廣",
      "economy":"經貿資訊",
      "member":"商會會員",
      "about":"關於商會",
      "contactus":"聯絡我們",
      "department":"相關商會",
      "dropdown":{
        "vision_mission":"願景和使命",
        "about_thaichinese":"歷史",
        "about_president_thaichinese":"泰國中華總商會主席",
        "relation":"商會新聞",
        "activity":"活動推廣",
        "relation_media":"精彩視頻",
        "ThaiChinese_articles":"經貿文章",
        "ThaiChinese_belief":"中總經濟指數",
        "Capital_investment_thai":"在泰投資",
        "Capital_investment_china":"在華投資",
        "board_member":"會董名錄",
        "hornor_member":"榮職名錄",
        "member":"會員名錄",
        "register":"加入會員",
        "Entrepreneurs_Convention":"世界華商大會顧問委員會",
        "Joint_Foreign":"在泰外國商會聯合會",
        "ThaiCC_Related":"泰國中華總商會暨各行業公會聯合會",
      }
    },
    "register":{
      "member":"入會說明",
      "register":"入會申請表",
      "download":"下載文件"
    },
    "vision_mission":{
      "vision":'願景',
      "mission":"使命"
    },
    "about":{
      "chamber_of_commerce_network":'泰國外國商會聯合會',
      "History_title":"商會簡介"
    },
    "president":{
      "president":"主席簡介",
      "president_previous":"歷屆主席"
    },
    "boardMember":{
      "header1":"第27屆",
      "header2":"會董會",
      "vice_president":"副主席",
      "secretary_general":"秘書長",
      "deputy_secretary_general":"副秘書長",
      "assistant_secretary_general":"秘書長助理",
      "error_search_invalid":"กรุณาเลือกตำแหน่งและฝ่าย",
      "error_search_title":"เกิดข้อผิดพลาด",
      "and": "和"
    },
    "home":{
      "vision_mission":"願景和使命",
      "about":"歷史",
      "economyThaiChinese":"中泰經濟",
      "highlight":"焦點",
      "ThaiChinese_belief":"經濟指數",
      "relation":"泰國中華總商會",
      "more":"更多",
      "president":"泰國中華總商會主席",
    },
    "honor":{
      "status":"榮譽主席"
    },
    "socialMedia":{
      "share":"分享"
    },
    "searchBoardMember":{
      "title":"The 28TH BOARD OF DIRECTION",
      "topsearch":"搜索結果"
    },
    "MemberDirectory":{
      "type_company":"公司類型",
      "placeholder" :"搜索",
      "goBack": "背部"
    }
  }